<script setup lang="ts">
  import {computed} from 'vue';
  import useCpBem from '~/composables/useCpBem';
  import {isValidUrl} from '~/utils';

  const props = withDefaults(defineProps<{
    disabled?: boolean,
    picture: string,
    title?: string,
    height: number|`${number}`
    width: number|`${number}`
    cover?: boolean
    localFile?: boolean
  }>(), {
    disabled: false,
    title: '',
    localFile: false,
  });

  const maxWidth = computed(() => props.width + 'px');
  const maxHeight = computed(() => props.height + 'px');

  const {b, em} = useCpBem('cp-product-image');

  const BASE_URL = 'https://www.cyberpuerta.mx';
  const imageUri = computed(() => isValidUrl(props.picture) ? props.picture : new URL(props.picture, BASE_URL).href);
</script>

<template>
    <div :class="b">
        <v-img
            :src="imageUri"
            :alt="title"
            :class="em('img', { disabled: disabled, cover })"
            loading="lazy"
        />
    </div>
</template>

<style scoped lang="scss">
.cp-product-image {
  display: flex;
  align-items: center;
  justify-content: center;
  height: v-bind(maxHeight);
  width: v-bind(maxWidth);

  &__img {
    width: 100%;
    height: 100%;
    object-fit: contain;

    &--disabled {
      opacity: 0.4;
    }
    &--cover {
      object-fit: cover;
      border-radius: $cp-border-radius;
    }
  }
}
</style>
